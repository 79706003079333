<template>
  <div>
    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">

      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-if="!loading" :loading="loading">
          <a-form-item style="margin-bottom: 5px">
            <div class="ant-page-header-heading-title">{{ selectedItem.status=='published'||selectedItem.status=='Published'?'Image Album Summary':'Publish Your Image Album' }}</div>
            <router-link :to="{name:'Edit Album | Basic Info',params:{id:selectedItem._id}}">
              <a-button @click="$emit('changeTab','1')"
                  size="small" style="float: right" type="primary" >
                Edit
              </a-button>
            </router-link>
            <a-button :disabled="selectedItem&&selectedItem.imagesDetail.length?false:true" v-if="selectedItem.status=='draft'||selectedItem.status=='Draft'"
                      size="small" style="float: right;margin-right:20px" type="primary" @click="publishPost()" :loading="confirmLoading">
              Publish Image Album
            </a-button>
            <a-dropdown>
              <a-menu slot="overlay" @click="handleMenuClick">
                <a-menu-item key="11" v-if="selectedItem.status=='draft'||selectedItem.status=='Draft'"> Mark As Deleted </a-menu-item>
                <a-menu-item key="22" v-if="selectedItem.status=='published'||selectedItem.status=='Published'"> Make Inactive </a-menu-item>
                <a-menu-item key="33" v-if="selectedItem.status=='deleted'||selectedItem.status=='Deleted'"> Mark As Draft </a-menu-item>
                <a-menu-item key="44" v-if="selectedItem.status=='inactive'||selectedItem.status=='Inactive'"> Publish Album </a-menu-item>
              </a-menu>
              <a-tag class="tag-status ant-tag-primary" style="cursor:pointer;margin-left: 8px;float: right;margin-right:20px;font-size:15px;padding: 7px 15px;" :class="{'ant-tag-success':selectedItem.status=='published'||selectedItem.status=='Published','ant-tag-danger':selectedItem.status=='deleted'||selectedItem.status=='Deleted','ant-tag-warning':selectedItem.status=='inactive'||selectedItem.status=='Inactive'}">
                {{ selectedItem?selectedItem.status:'' | capitalizeFirstLetter }} <a-icon type="down" style="color:white"/> </a-tag>
            </a-dropdown>

          </a-form-item>

          <a-row v-if="selectedItem&&!selectedItem.imagesDetail.length" style="margin-top: 15px;margin-bottom: 8px;" :gutter="24" type="flex">
            <a-col :span="24">
              <a-alert  type="error" show-icon >
              <span slot="message">
              No image has been uploaded yet. Please upload at least one image <router-link to="images"><span v-on:click="$emit('changeTab','2')">Images & Sources</span></router-link> tab in order to publish the image album.
              </span>
              </a-alert>
            </a-col>
          </a-row>
          <div style="margin-top: 20px;" v-if="selectedItem&&selectedItem.imagesDetail.length">
            <a-carousel  arrows dots-class="slick-dots slick-thumb">
              <div
                  slot="prevArrow"
                  slot-scope="props"
                  class="custom-slick-arrow"
                  style="left: 10px;zIndex: 1"
              >
                <a-icon type="left-circle" />
              </div>
              <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                <a-icon type="right-circle" />
              </div>
              <a slot="customPaging" slot-scope="props">
                <img :src="selectedItem.imagesDetail[props.i].type=='image_source'?selectedItem.imagesDetail[props.i].sourceUrl:url+selectedItem.imagesDetail[props.i].uploadPath" />
              </a>
              <div  v-for="item in selectedItem.imagesDetail">
                <img width="100%" height="300" style="object-fit: contain;max-width: 100%;" :src="item.type=='image_source'?item.sourceUrl:url+item.uploadPath" />
              </div>
            </a-carousel>
            <br>
            <br>
          </div>



          <ul class="ant-list-items">
            <li class="ant-list-item" style="justify-content: unset">
              <div class="ant-list-item-extra">
                <img v-if="selectedItem&&!selectedItem.imagesDetail.length" width="272" alt="logo"
                                                    src="/images/no-image.jpg"
                                                    style="height: 180px; object-fit: cover;border-radius: 5px;"></div>
              <div class="ant-list-item-main" :style="selectedItem&&selectedItem.imagesDetail.length?{'margin-top': '15px'}:{'padding': '15px 15px 15px 25px'}">
                <div class="ant-list-item-meta">
                  <div class="ant-list-item-meta-content">
                    <h4 class="ant-list-item-meta-title text-primary">
                      <div style="display: flex;font-size: 16px"> {{selectedItem.title}}
                      </div>
                    </h4>
                  </div>
                </div>
                <div v-if="selectedItem">
                  <a-icon type="calendar" style="margin-right: 8px" />
                  <span>{{ selectedItem.date | moment("D MMM YYYY, hh:mm A") }} </span>
                  |
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                  {{(selectedItem.address.post_code + ', ' + selectedItem.address.district + ', ' + selectedItem.address.country)
                  }}
                </div>
                <div class="table-avatar-info" style="align-items: center;margin-top: 6px;">
                  <a-avatar shape="square" :src="selectedItem?url+selectedItem.collectionDetail.image:''" style="margin-right: 8px;"></a-avatar>
                  <div class="avatar-info">
                    <h6>{{ selectedItem?selectedItem.collectionDetail.title:'' }}</h6>
                  </div>
                  <div class="avatar-info">
                    <a-tooltip title="Related Event">
                    <h6  v-if="selectedItem.event" style="margin:0 5px 3px 20px;max-width: 480px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;line-height: 22px">
                      <a-icon type="calendar" style="margin-right: 6px" />
                      <router-link class="event-title"  :to="{

        name: 'Edit Event | Basic Info',
        params: { id: selectedItem.event }
      }" >
                        {{ selectedItem.eventDetail.title }} <span style="color:rgba(0, 0, 0, 0.45);font-weight: 600">#{{selectedItem.eventDetail.eventNumber}}</span>
                      </router-link>
                    </h6>
                    </a-tooltip>
                    <h6 v-if="!selectedItem.event" style="margin:0 5px 3px 20px;max-width: 455px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;">
                      <a-icon type="calendar" style="margin-right: 6px;color: red" />
                      <a-tag size="small" color="red">
                        No event selected
                      </a-tag>
                    </h6>
                  </div>
                </div>

                <div style="color: rgba(0, 0, 0, 0.45); font-size: 14px; font-weight: 600; margin: 0.5rem 0px 0.5rem 0;">
                  <a-avatar style="margin-right: 0px" v-if="selectedItem&&selectedItem.user.imageUrl" slot="avatar"
                            :src="selectedItem.user.imageUrl"/>
                  <a-avatar v-else slot="avatar" style="background-color: #1890ff;margin-right: 0px">
                    {{ (selectedItem ? selectedItem.user.name : '')|getNameForAvatar }}
                  </a-avatar>
                  Created by {{ selectedItem?selectedItem.user.name:'' }} on {{ selectedItem.createdAt | moment("MMM D YYYY, hh:mm A") }}
                </div>
                <ul class="ant-list-item-action"  style="margin-left: 0">
                  <li v-if="selectedItem.status=='Published'||selectedItem.status=='published'||selectedItem.status=='Inactive'||selectedItem.status=='inactive'">
                    {{ selectedItem.viewsCount?selectedItem.viewsCount:'No' }} {{ selectedItem.viewsCount==1?'View':'Views' }} <em class="ant-list-item-action-split"></em></li>
                  <li v-if="selectedItem.status=='Published'||selectedItem.status=='published'||selectedItem.status=='Inactive'||selectedItem.status=='inactive'">
                    {{ selectedItem.likesCount?selectedItem.likesCount:'No' }} {{ selectedItem.likesCount==1?'Like':'Likes' }} <em class="ant-list-item-action-split"></em></li>
                  <li v-if="selectedItem.status=='Published'||selectedItem.status=='published'||selectedItem.status=='Inactive'||selectedItem.status=='inactive'">
                    {{ selectedItem.commentsCount?selectedItem.commentsCount:'No' }} {{ selectedItem.commentsCount==1?'Comment':'Comments' }} <em class="ant-list-item-action-split"></em></li>
                  <li v-if="selectedItem.status=='Published'||selectedItem.status=='published'||selectedItem.status=='Inactive'||selectedItem.status=='inactive'">
                    {{ selectedItem.bookmarksCount?selectedItem.bookmarksCount:'No' }} {{ selectedItem.bookmarksCount==1?'Bookmark':'Bookmarks' }} <em class="ant-list-item-action-split"></em></li>
                  <li v-if="selectedItem.status=='Published'||selectedItem.status=='published'||selectedItem.status=='Inactive'||selectedItem.status=='inactive'">
                    {{ selectedItem.reportsCount?selectedItem.reportsCount:'No' }} {{ selectedItem.reportsCount==1?'Report':'Reports' }} <em class="ant-list-item-action-split"></em></li>
                  <li>Modified {{ moment(selectedItem.updatedAt).fromNow() }}
                  </li>
                </ul>
              </div>

            </li>

            <li class="ant-list-item" style="justify-content: unset" v-if="selectedItem&&selectedItem.tags.length">
              <div>
                <a-form-item style="margin-bottom: 15px">
                  <h4 class="ant-page-header-heading-title">Tags</h4>
                </a-form-item>
                  <div style="display: flex">
                    <a-tag color="blue" :key="index" v-for="(tag,index) in selectedItem.tags">
                      {{ tag }}
                    </a-tag>
                  </div>
              </div>
            </li>
            <li class="ant-list-item" style="justify-content: unset">
              <div>
                <a-form-item style="margin-bottom: 15px">
                  <h4 class="ant-page-header-heading-title">Description</h4>
                </a-form-item>
                  <div v-if="selectedItem.description" v-html="selectedItem.description">
                  </div>
                <div v-else>Description not added</div>
              </div>
            </li>
          </ul>


<!--          <a-card hoverable style="width: 300px">-->
<!--            <img-->
<!--                slot="cover"-->
<!--                alt="example"-->
<!--                src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"-->
<!--            />-->
<!--            <template slot="actions" class="ant-card-actions">-->
<!--              <a-icon key="setting" type="setting"/>-->
<!--              <a-icon key="edit" type="edit"/>-->
<!--              <a-icon key="ellipsis" type="ellipsis"/>-->
<!--            </template>-->
<!--            <a-card-meta title="Card title" description="This is the description">-->
<!--              <a-avatar-->
<!--                  slot="avatar"-->
<!--                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"-->
<!--              />-->
<!--            </a-card-meta>-->
<!--          </a-card>-->
        </a-card>

        <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
          <a-row :gutter="[24, 24]">
            <a-col :span="24" >
              <a-skeleton active />
              <a-skeleton avatar  active />
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
          </a-row>
        </a-card>
      </a-col>

    </a-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      moment,
      url: process.env.VUE_APP_SERVER_URL,
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this),
    };
  },
  computed: {
    selectedItem() {
      return this.$store.getters.selectedPost
    },
  },
  mounted() {
    this.postInfo()
  },
  methods: {

    postInfo(){
      this.loading=true
      this.$store.dispatch("FETCH_POST",{id:this.$route.params.id})
          .then((res)=>{
          })
          .finally(()=>{
            this.loading=false
          })
    },
    submitForm(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          this.$store.dispatch("UPDATE_EVENT", {id: this.id, form: formData})
              .then((res) => {
                this.$message.success(res.data.message);
                this.$store.commit('CHANGE_DATE_TYPE', values.dateType)
                if (values.dateType == 'recurring') {
                  this.$emit('changeTab', '6')
                } else
                  this.$emit('changeTab', '2')
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.confirmLoading = false
              });
        }
      });
    },
    handleMenuClick(key){
      this.$confirm({
        title: 'Are you sure want to update the image album?',
        content: 'Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          let status=this.selectedItem.status
          if (key.key=='11')
            status='deleted'
          else if (key.key=='22')
            status='inactive'
          else if (key.key=='33')
            status='draft'
          else if (key.key=='44')
            status='published'
          this.confirmLoading = true
          this.$store.dispatch("UPDATE_POST_STATUS", {status:status,id:this.selectedItem._id})
              .then((res) => {
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.confirmLoading = false
              });
        },
      });

    },
    publishPost(){
      this.$confirm({
        title: 'Are you sure want to publish the image album?',
        content: 'Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
            let status='published'
          this.confirmLoading = true
          this.$store.dispatch("UPDATE_POST_STATUS", {status:status,id:this.selectedItem._id})
              .then((res) => {
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.confirmLoading = false
              });
        },
      });

    },
  }
}
</script>
<!--<style >-->
<!--/*.ant-card-cover img{*/-->
<!--/*  border-radius: 12px!important;*/-->
<!--/*}*/-->
<!--.layout-dashboard .ant-card {-->
<!--  border-radius: 0 !important;-->
<!--}-->
<!-- -->
<!--</style>-->
<style scoped>
/* For demo */
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

</style>